import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Empty } from 'antd'

function showImage(url) {
  const frameid = 'frameimg' + Math.random();
  (window as any).img =
    '<img id="img" src=\'' +
    url +
    '?' +
    Math.random() +
    "' /><script>window.onload = function() { parent.document.getElementById('" +
    frameid +
    "').height = document.getElementById('img').height+'px'; }<" +
    '/script>';
  return (
    '<iframe id="' + frameid + '" src="javascript:parent.img;" frameBorder="0" scrolling="no" width="100%"></iframe>'
  );
}

export default function News() {
  const [articleList, setArticleList] = useState([] as any[]);
  useEffect(() => {
    !articleList.length &&
      fetch('https://wx.qingpai365.com/wx/material?appId=wx8994d045ae5428e8&type=news&offset=0&count=20')
        .then((res) => res.json())
        .then((data) => {
          if(data.errcode) {

          }
          if(data.item) {
            const list = data.item
            .map((item) => item.content.news_item.map((item1) => ({ ...item1, create_time: item.content.create_time })))
            .reduce((prev, next) => [...prev, ...next]);

            setArticleList(list);
          }
 
         
        });
  });

  return (
    <Layout nav="news">
      <SEO title="轻派头条" />
      <section className="py-4" style={{ backgroundColor: '#293D5D' }}>
        <div className="container mx-auto px-4 sm:px-16 flex items-center justify-between">
          <div>
            <h1 className="text-3xl sm:text-4xl text-white">轻派头条</h1>
            <h2 className="text-xs text-white opacity-60">Qingpai News</h2>
          </div>
          <img src={require('../assets/87d288.png')} alt="图片" className="h-40 sm:hidden" />
        </div>
      </section>

      <section className="bg-white py-8 min-h-screen">
        <div className="container mx-auto px-4">
          {articleList.map((article) => (
            <a href={article.url} target="_blank">
              <article key={article.title} className="py-4">
                <h3 className="mb-1 text-xl text-gray-900 font-medium hover:text-primary">{article.title}</h3>
                <p className="text-sm text-gray-600">{article.digest}</p>
                <time className="mt-4 text-xs text-gray-300">
                  {dayjs(article.create_time * 1000).format('YYYY-MM-DD')}
                </time>
              </article>
            </a>
          ))}
          {articleList.length == 0 && '暂无数据'}
        </div>
      </section>
    </Layout>
  );
}
